import { Card, Divider, Row, Col, Breadcrumb } from 'antd';
import { ExperimentOutlined, HomeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import ConfigTabView from '@/components/configurations/ConfigTabView';

const ConfigurationsScreen = () => {
  const { t } = useTranslation();

  return (
    <div className="dashboard-screen fadeIn">
      <Card>
        <Row justify="space-between" align="middle">
          <Col>
            <Breadcrumb
              items={[
                {
                  href: '',
                  title: <HomeOutlined />,
                },
                {
                  href: '',
                  title: (
                    <>
                      <ExperimentOutlined />
                      <span>{t('menu.params')}</span>
                    </>
                  ),
                },
              ]}
            />
          </Col>
        </Row>
        <Divider />
        <ConfigTabView />
      </Card>
    </div>
  );
};

export default ConfigurationsScreen;
