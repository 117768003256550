import { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Skeleton,
  Tabs,
} from 'antd';
import { ChromePicker } from 'react-color';
import { useTranslation } from 'react-i18next';

import APIService from '@/services/API';
import { ConfElement, RawConfiguration } from '@/interfaces/configuration.interface';

function parseData(data: RawConfiguration[]): RawConfiguration[] {
  for (const index in data) {
    for (const key in data[index].elements) {
      if (data[index].elements[key].type === 'json') {
        data[index].elements[key].value = JSON.stringify(data[index].elements[key].value);
      }
    }
  }

  return data;
}

function restoreData(elements: ConfElement[]): ConfElement[] {
  const data: ConfElement[] = [];
  for (const element of elements) {
    if (element.type === 'json') {
      element.value = JSON.parse(element.value);
      data.push(element);
    } else {
      data.push(element);
    }
  }

  return data;
}

const ConfigTabView = () => {
  const [data, setData] = useState<RawConfiguration[]>([]);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    async function get() {
      setLoading(true);
      const response = await APIService.getConfigs();
      if (response?.data?.length) {
        setData(parseData(response.data));
      }
      setLoading(false);
    }
    get();
  }, []);

  const handleSave = async () => {
    let blnError = false;
    setBtnLoading(true);
    for (const { _id, elements } of data) {
      const response = await APIService.updateConfig(_id, {
        elements: restoreData(elements),
      });

      if (!response.ok) {
        message.success(t('configurations.errorUpdate'));
        blnError = true;
        break;
      }
    }
    setBtnLoading(false);
    if (!blnError) {
      message.success(t('configurations.updateSuccess'));
    }
  };

  const handleChangeValue = (value: any, index: number, key: string) => {
    setData((prevItems) =>
      prevItems.map((item) => {
        if (item.slug === key) {
          return {
            ...item,
            elements: item.elements.map((el, i) => {
              if (i === index) {
                return { ...el, value };
              }

              return el;
            }),
          };
        }

        return item;
      }),
    );
  };

  const renderInput = (node: ConfElement, index: number, key: string) => {
    switch (node.type) {
      case 'select':
        return (
          <Select
            value={node.value}
            style={{ width: '100%' }}
            onChange={(e) => handleChangeValue(e, index, key)}
          >
            {node?.options?.map((item, key) => (
              <Select.Option key={key} value={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        );
      case 'number':
        return (
          <InputNumber
            min={0}
            style={{ width: '100%' }}
            value={node.value}
            onChange={(e) => handleChangeValue(e, index, key)}
          />
        );
      case 'json':
        return (
          <Input.TextArea
            value={node.value}
            onChange={(e) => handleChangeValue(e, index, key)}
          />
        );
      case 'color':
        return (
          <ChromePicker
            color={node.value}
            onChange={(e) => handleChangeValue(e.hex, index, key)}
          />
        );
      default:
        return (
          <Input
            style={{ width: '100%' }}
            value={node.value}
            onChange={(e) => handleChangeValue(e.target.value, index, key)}
          />
        );
    }
  };

  const renderElements = (elements: ConfElement[], key: string, index: number) => {
    return (
      <Row key={`row-${key}-${index}`} gutter={[0, 15]} justify="center">
        {elements.map((element, i) => (
          <Col key={element.slug} span={20}>
            <Row gutter={[10, 0]} align="middle">
              <Col md={6} className="text-right">
                {element.name}
              </Col>
              <Col flex="1">{renderInput(element, i, key)}</Col>
            </Row>
          </Col>
        ))}
      </Row>
    );
  };

  const renderContent = () => {
    if (loading) {
      return <Skeleton active />;
    }

    return (
      <Tabs
        items={data.map((row, i) => {
          return {
            key: row.slug,
            label: row.name,
            children: (() => <>{renderElements(row.elements, row.slug, i)}</>)(),
          };
        })}
      />
    );
  };

  return (
    <>
      <Row justify="end">
        <Col>
          <Button type="primary" onClick={handleSave} loading={btnLoading}>
            {t('general.save')}
          </Button>
        </Col>
      </Row>
      {renderContent()}
    </>
  );
};

export default ConfigTabView;
